@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  > div {
    display: flex;
    flex-direction: column;
    transition: all variables.$transition-duration variables.$transition-timing-function;
    z-index: variables.$z-index-4;
    padding: variables.$spacing-lg;
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 0 variables.$spacing-lg;
  height: 71px;
  border-bottom: 1px solid colors.$color-black-10;

  .title {
    display: flex;
    align-items: center;
    flex: 1;

    h3 {
      font-size: variables.$font-size-fine;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .controls {
    display: flex;
    align-items: center;

    > a {
      font-size: convert-to-rem(16);
      font-weight: 500;
    }

    .menu button,
    .close {
      @include button.button-reset;
      outline: none;
    }

    .menu {
      border-right: 1px solid colors.$color-black-10;
      padding-right: 10px;
      margin-right: 10px;
    }

    .close {
      margin-left: auto;
    }
  }
}
