@use 'src/theme/styles/colors';
@use 'src/theme/styles/variables';
@use 'src/theme/styles/mixins/button';

.root {
  min-height: 400px;
}

.header {
  display: flex;
  align-items: center;
  padding: 27px variables.$spacing-md;
  border-bottom: 1px solid colors.$color-carbon-accent;
  justify-content: right;

  .close {
    @include button.button-reset;
    outline: none;
    border-left: 1px solid colors.$color-black-10;
    padding-left: 10px;
    margin-left: 10px;
  }

  .menu {
    padding-left: 10px;
    margin-left: 20px;
  }
}

.body {
  margin: variables.$spacing-sm;
  height: 100%;

  :global {
    .rbn--row {
      height: 100%;
    }
    .rbn--col {
      border-right: 1px solid colors.$color-carbon-tint;
    }
  }
}
